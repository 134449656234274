import { useTranslation } from 'react-i18next';
import { Header, Tab, Tabs, Warning } from '@hubportal/components';
import OrderedRequestsOverview from 'partials/ordered-requests-overview';
import useStore from 'utils/store';
import AssignedOverview from 'partials/assigned-overview';
import ClaimedOverview from 'partials/claimed-overview';
import { ReactElement, useEffect } from 'react';
import EligibleOverview from 'partials/eligible-overview';
import ArchiveOverview from 'partials/archive-overview';

const OVERVIEW_TABS = {
  ELIGIBLE: 'eligible',
  REQUESTED: 'requested',
  ASSIGNED: 'assigned',
  CLAIMED: 'claimed',
  ARCHIVE: 'archive',
};

const Equipment = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    equipmentSection,
    setEquipmentSection,
    bundleStatusChangeError,
    setBundleStatusChangeError,
  } = useStore();

  const renderOverview = (equipmentSectionval: string): ReactElement => {
    switch (equipmentSectionval) {
      case OVERVIEW_TABS.ASSIGNED:
        return <AssignedOverview />;
      case OVERVIEW_TABS.REQUESTED:
        return <OrderedRequestsOverview />;
      case OVERVIEW_TABS.CLAIMED:
        return <ClaimedOverview />;
      case OVERVIEW_TABS.ELIGIBLE:
        return <EligibleOverview />;
      case OVERVIEW_TABS.ARCHIVE:
        return <ArchiveOverview />;
      default:
        return <OrderedRequestsOverview />;
    }
  };

  useEffect(() => {
    if (bundleStatusChangeError) {
      const timeout = setTimeout(() => {
        setBundleStatusChangeError({
          title: '',
          message: '',
        });
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [bundleStatusChangeError]);

  return (
    <>
      <Header title={t('header')}>
        <div className="flex gap-6">
          <Tabs value={equipmentSection} onChange={setEquipmentSection}>
            <Tab id="eligible" icon="numberCircle1">
              {t('eligible_tab_title')}
            </Tab>
            <Tab id="requested" icon="numberCircle2">
              {t('ordered_tab_title')}
            </Tab>
            <Tab id="assigned" icon="numberCircle3">
              {t('assigned_tab_title')}
            </Tab>
            <Tab id="claimed" icon="numberCircle4">
              {t('claimed_tab_title')}
            </Tab>
            <Tab id="archive" icon="archiveEquipment">
              {t('archive_tab_title')}
            </Tab>
          </Tabs>
        </div>
      </Header>

      {bundleStatusChangeError.title && bundleStatusChangeError.message && (
        <div className={`text-black transition ease-in-out duration-300`}>
          <Warning
            title={bundleStatusChangeError.title}
            description={bundleStatusChangeError.message}
            onDismiss={() =>
              setBundleStatusChangeError({ title: '', message: '' })
            }
            icon="caution"
            dismissText={t('dismiss_text')}
          />
        </div>
      )}

      {renderOverview(equipmentSection)}
    </>
  );
};

export { Equipment };
