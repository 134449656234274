export enum EquipmentStatus {
  REQUESTED = 'requested',
  ORDERED = 'ordered',
  DELIVERED = 'delivered',
  UNCLAIMED = 'unclaimed',
  REJECTED = 'rejected',
  CONFIRMED = 'confirmed',
  CLAIMED = 'claimed',
  RETURNED = 'returned',
  ASSIGNED = 'assigned',
}

export enum RejectReasons {
  MISSING_ITEMS = 'item_missing',
  INCORRECT_SIZE = 'wrong_size',
  DAMAGED_ITEMS = 'damaged',
  OTHER = 'other',
}
