import { useTranslation } from 'react-i18next';

const EmptyState = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col h-96 items-center mt-12 text-white">
      <h2 className="text-xl font-bold">{t('empty_state.title')}</h2>
      <div className="w-64 mt-2 text-base text-center font-normal">
        {t('empty_state.description')}
      </div>
    </div>
  );
};

export default EmptyState;
