import {
  Button,
  ButtonVariant,
  Checkbox,
  Modal,
  ModalSize,
  Pagination,
} from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentStatus, RejectReasons } from 'utils/constants';
import useEquipmentRequests, {
  EquipmentRequestsQuery,
} from 'utils/hooks/useEquipmentRequests';
import { updateEquipmentRequest } from 'utils/network/apis';
import useStore from 'utils/store';
import { AssignedOverviewTable } from './table';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_RIDERS_QUERY = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

type AssignedAction = 'markAsRejected' | 'markAsUnclaimed' | 'next' | null;

const AssignedOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub, setBundleStatusChangeError } = useStore();

  const [riders, setRiders] = useState<RiderEquipment[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [modal, setModal] = useState<AssignedAction>(null);
  const [rejectReason, setRejectReason] = useState<RejectReasons | null>(null);

  const [query, setQuery] = useState<EquipmentRequestsQuery>({
    ...DEFAULT_RIDERS_QUERY,
    selectedStates: [EquipmentStatus.DELIVERED],
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  useEffect(() => {
    setQuery({
      ...query,
      limit: pagination.pageSize,
      offset: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  const { error, data, refetch } = useEquipmentRequests(
    selectedHub.slug,
    query
  );

  useEffect(() => {
    setRiders(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) / (data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  const changeItemStatus = async (state: EquipmentStatus): Promise<void> => {
    const payload: RejectRequestPayload = {
      state,
    };
    if (state === EquipmentStatus.REJECTED && rejectReason) {
      payload.reject_reason = rejectReason;
    }
    try {
      await updateEquipmentRequest(selectedItem, payload);
      refetch();
    } catch (err: any) {
      const newError = {
        title: t('error.bundle_status_change_error.title'),
      };
      if (err.response.status === 403) {
        setBundleStatusChangeError({
          ...newError,
          message: t('error.module_access_error.message'),
        });
      } else {
        setBundleStatusChangeError({
          ...newError,
          message: t('error.bundle_status_change_error.message'),
        });
      }
    } finally {
      setSelectedItem(null);
      setModal(null);
      setRejectReason(null);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex gap-x-2 px-10 justify-end py-5">
        <Button
          onClick={() => {
            setModal('markAsRejected');
          }}
          disabled={!selectedItem}
        >
          {t('mark_as_rejected')}
        </Button>
        <Button
          onClick={() => {
            setModal('markAsUnclaimed');
          }}
          disabled={!selectedItem}
        >
          {t('mark_as_unclaimed')}
        </Button>
        <Modal
          open={modal === 'markAsUnclaimed'}
          header={t('delivered_reject_reason_unclaimed_modal_header')}
          size={ModalSize.small}
        >
          <div>
            <div className="my-4">
              {t('delivered_reject_reason_unclaimed_modal_body')}
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                variant={ButtonVariant.secondary}
                onClick={() => setModal(null)}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  changeItemStatus(EquipmentStatus.UNCLAIMED);
                  setModal(null);
                }}
              >
                {t('confirm')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={modal === 'markAsRejected'}
          header={t('deliver_overview_reject_reasons_header')}
          size={ModalSize.small}
        >
          <div>
            <div className="my-4 grid grid-rows-4 grid-flow-col gap-4">
              <Checkbox
                id="reject_reasons_missing_item"
                checked={rejectReason === RejectReasons.MISSING_ITEMS}
                onChange={() => setRejectReason(RejectReasons.MISSING_ITEMS)}
              >
                {t('reject_reasons_missing_item')}
              </Checkbox>
              <Checkbox
                id="reject_reasons_incorrect_size"
                checked={rejectReason === RejectReasons.INCORRECT_SIZE}
                onChange={() => setRejectReason(RejectReasons.INCORRECT_SIZE)}
              >
                {t('reject_reasons_incorrect_size')}
              </Checkbox>
              <Checkbox
                id="reject_demaged_item"
                checked={rejectReason === RejectReasons.DAMAGED_ITEMS}
                onChange={() => setRejectReason(RejectReasons.DAMAGED_ITEMS)}
              >
                {t('reject_demaged_item')}
              </Checkbox>
              <Checkbox
                id="other"
                checked={rejectReason === RejectReasons.OTHER}
                onChange={() => setRejectReason(RejectReasons.OTHER)}
              >
                {t('other')}
              </Checkbox>
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                variant={ButtonVariant.secondary}
                onClick={() => {
                  setModal(null);
                  setRejectReason(null);
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  setModal('next');
                }}
                disabled={!rejectReason}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={modal === 'next'}
          header={t('deliver_overview_reject_reasons_confirm_header')}
          onClose={() => setModal(null)}
          size={ModalSize.small}
        >
          <div>
            <div className="my-4">
              {t('deliver_overview_reject_reasons_confirm_body')}
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                variant={ButtonVariant.secondary}
                onClick={() => {
                  setModal(null);
                  setRejectReason(null);
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  changeItemStatus(EquipmentStatus.REJECTED);
                  setModal(null);
                }}
              >
                {t('confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="relative mb-16">
        {error && <div className="text-pink text-xs">{error}</div>}
        <AssignedOverviewTable
          assignedRiders={riders}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
    </div>
  );
};

export default AssignedOverview;
