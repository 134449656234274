import {
  Button,
  Pagination,
  Modal,
  ModalSize,
  ButtonVariant,
} from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentStatus } from 'utils/constants';
import useEquipmentRequests, {
  EquipmentRequestsQuery,
} from 'utils/hooks/useEquipmentRequests';
import { updateEquipmentRequest } from 'utils/network/apis';
import useStore from 'utils/store';
import { EquipmentRequestsTable } from './table';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_RIDERS_QUERY = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

export type OrderedEquipmentResponse = {
  pagination?: { total?: number; limit?: number };
  results?: RiderEquipment[];
};

const OrderedRequestsOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub, setBundleStatusChangeError } = useStore();

  const [riders, setRiders] = useState<RiderEquipment[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  const [query, setQuery] = useState<EquipmentRequestsQuery>({
    ...DEFAULT_RIDERS_QUERY,
    selectedStates: [EquipmentStatus.ORDERED],
  });

  const { error, data, refetch } = useEquipmentRequests(
    selectedHub.slug,
    query
  );

  useEffect(() => {
    setRiders(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) / (data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  useEffect(() => {
    setQuery({
      ...query,
      limit: pagination.pageSize,
      offset: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  const changeItemStatus = async (): Promise<void> => {
    try {
      await updateEquipmentRequest(selectedItem, {
        state: EquipmentStatus.DELIVERED,
      });
      refetch();
    } catch (err: any) {
      const newError = {
        title: t('error.bundle_status_change_error.title'),
      };
      if (err.response.status === 403) {
        setBundleStatusChangeError({
          ...newError,
          message: t('error.module_access_error.message'),
        });
      } else {
        setBundleStatusChangeError({
          ...newError,
          message: t('error.bundle_status_change_error.message'),
        });
      }
    } finally {
      setSelectedItem(null);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex gap-x-2 px-10 justify-end py-5">
        <Button onClick={() => setModalOpen(true)} disabled={!selectedItem}>
          {t('mark_as_assigned')}
        </Button>
        <Modal
          open={modalOpen}
          header={t('ordered_request_modal_header')}
          size={ModalSize.small}
        >
          <div>
            <div className="my-4">{t('ordered_request_modal_body')}</div>
            <div className="flex gap-2 justify-end">
              <Button
                variant={ButtonVariant.secondary}
                onClick={() => setModalOpen(false)}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  changeItemStatus();
                  setModalOpen(false);
                }}
              >
                {t('confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="relative mb-16">
        {error && <div className="text-pink text-xs">{error}</div>}
        <EquipmentRequestsTable
          eligibleRiders={riders}
          selectedItem={selectedItem}
          setSelectItem={setSelectedItem}
        />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
    </div>
  );
};

export default OrderedRequestsOverview;
