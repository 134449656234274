import create from 'zustand';

type Country = 'DE' | 'AT' | 'FR' | 'NL';

type BundleErrorType = {
  title?: string;
  message?: string;
};

type Store = {
  country: Country;
  selectedHub: { slug: string };
  hubs: { id: string }[];
  countries: { id: string }[];
  equipmentSection: string;
  bundleStatusChangeError: BundleErrorType;
  setBundleStatusChangeError: (
    bundleStatusChangeError: BundleErrorType
  ) => void;
  setHubs: (hubs: { id: string }[]) => void;
  setCountry: (country: Country) => void;
  setSelectedHub: (hub: { slug: string }) => void;
  setEquipmentSection: (equipmentSection: string) => void;
};

const useStore = create<Store>((set) => ({
  country: 'DE',
  selectedHub: { slug: '' },
  hubs: [],
  countries: [{ id: 'de' }, { id: 'at' }, { id: 'fr' }, { id: 'nl' }],
  equipmentSection: 'eligible',
  bundleStatusChangeError: { title: '', message: '' },
  setBundleStatusChangeError: (bundleStatusChangeError) =>
    set({ bundleStatusChangeError }),
  setHubs: (hubs) => set({ hubs }),
  setCountry: (country) => set({ country }),
  setSelectedHub: (selectedHub) => set({ selectedHub }),
  setEquipmentSection: (equipmentSection) => set({ equipmentSection }),
}));

export default useStore;
export const { getState } = useStore;
