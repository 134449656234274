import { EquipmentStatus } from 'utils/constants';
import useEquipmentRequests, {
  EquipmentRequestsQuery,
} from 'utils/hooks/useEquipmentRequests';
import { updateEquipmentRequest } from 'utils/network/apis';
import { ClaimedOverviewTable } from './table';

import {
  Button,
  ButtonVariant,
  Checkbox,
  Modal,
  ModalSize,
  Pagination,
} from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStore from 'utils/store';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_RIDERS_QUERY = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

const ClaimedOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub, setBundleStatusChangeError } = useStore();

  const [riders, setRiders] = useState<RiderEquipment[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isReturnConfirmed, setIsReturnConfirmed] = useState<boolean>(false);

  const [query, setQuery] = useState<EquipmentRequestsQuery>({
    ...DEFAULT_RIDERS_QUERY,
    selectedStates: [EquipmentStatus.CLAIMED],
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  useEffect(() => {
    setQuery({
      ...query,
      limit: pagination.pageSize,
      offset: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  const { error, data, refetch } = useEquipmentRequests(
    selectedHub.slug,
    query
  );

  useEffect(() => {
    setRiders(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) / (data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  const changeItemStatus = async (state: EquipmentStatus): Promise<void> => {
    try {
      await updateEquipmentRequest(selectedItem, {
        state: state,
      });
      refetch();
    } catch (err: any) {
      if (err.response.status === 403) {
        setBundleStatusChangeError({
          title: t('error.bundle_status_change_error.title'),
          message: t('error.module_access_error.message'),
        });
      } else if (err.response.status === 400) {
        setBundleStatusChangeError({
          title: t(`error.${err?.response?.data?.fields?.error_key}.title`),
          message: t(`error.${err?.response?.data?.fields?.error_key}.message`),
        });
      } else {
        setBundleStatusChangeError({
          title: t('error.bundle_status_change_error.title'),
          message: t('error.bundle_status_change_error.message'),
        });
      }
    } finally {
      setSelectedItem(null);
      setIsReturnConfirmed(false);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      <div className="flex gap-x-2 px-10 justify-end py-5">
        <Button
          onClick={() => setModalOpen(true)}
          disabled={!selectedItem}
          data-testid="confirm-button"
        >
          {t('mark_as_returned')}
        </Button>
        <Modal
          open={modalOpen}
          header={t('returned_modal_header')}
          onClose={() => {
            setModalOpen(false);
            setIsReturnConfirmed(false);
          }}
          size={ModalSize.small}
          data-testid="confirm-modal"
        >
          <div>
            <div className="my-4">{t('returned_modal_body')}</div>
            <div className="flex justify-center pt-4 mb-8">
              <Checkbox
                checked={isReturnConfirmed}
                onChange={() => setIsReturnConfirmed(!isReturnConfirmed)}
              >
                <span className="text-xs">{t('returned_modal_checkbox')}</span>
              </Checkbox>
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                variant={ButtonVariant.secondary}
                onClick={() => {
                  setModalOpen(false);
                  setIsReturnConfirmed(false);
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  changeItemStatus(EquipmentStatus.RETURNED);
                  setModalOpen(false);
                }}
                disabled={!isReturnConfirmed}
              >
                {t('confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      <div className="relative mb-16">
        {error && <div className="text-pink text-xs">{error}</div>}
        <ClaimedOverviewTable
          claimedRiders={riders}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
    </div>
  );
};

export default ClaimedOverview;
