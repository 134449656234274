import React, { useEffect, useSyncExternalStore } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import useStore from 'utils/store';
import sdk from '@hubportal/sdk';

import { config } from './i18n';
import App from './App';

i18n.use(initReactI18next).init(config);

export default function Root({
  authClient,
}: PropsWithAuth0Client): JSX.Element {
  const { setCountry, setSelectedHub } = useStore();

  const hub = useSyncExternalStore(sdk.onHubChange, () => sdk.getHub());
  const locale = useSyncExternalStore(sdk.onLocaleChange, () =>
    sdk.getLocale()
  );

  const country = useSyncExternalStore(sdk.onCountryChange, () =>
    sdk.getCountry()
  );

  useEffect(() => {
    setSelectedHub(hub);
  }, [hub]);

  useEffect(() => {
    locale && typeof locale === 'string' && i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    setCountry(country);
  }, [country]);

  return (
    <React.StrictMode>
      <App authClient={authClient} />
    </React.StrictMode>
  );
}
