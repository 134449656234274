/**
 *
 * TODO:
 * This file contains all the apis for now but it should be splitted by domain in future
 * Responses should have proper types
 *
 *  */

import { fetch } from '..';

export const getEquipmentRequests = async (hubSlug, data): Promise<any> => {
  return fetch('GET', 'EQIPMENT_REQUESTS', { data, interpolate: { hubSlug } });
};

export const getEligibleRiders = async (hubSlug, data): Promise<any> => {
  return fetch('GET', 'ELIGIBLE_RIDERS', { data, interpolate: { hubSlug } });
};

export const updateEquipmentRequest = async (
  equipmentRequestID,
  data
): Promise<any> => {
  return fetch('PATCH', 'UPDATE_EQUIPMENT_STATUS', {
    data,
    interpolate: { equipmentRequestID },
  });
};
