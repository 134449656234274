import { Pagination } from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentStatus } from 'utils/constants';
import useEquipmentRequests, {
  EquipmentRequestsQuery,
} from 'utils/hooks/useEquipmentRequests';
import useStore from 'utils/store';
import { ArchiveOverviewTable } from './table';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_RIDERS_QUERY = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

const ArchiveOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub } = useStore();

  const [riders, setRiders] = useState<RiderEquipment[]>([]);

  const [query, setQuery] = useState<EquipmentRequestsQuery>({
    ...DEFAULT_RIDERS_QUERY,
    selectedStates: [
      EquipmentStatus.UNCLAIMED,
      EquipmentStatus.REJECTED,
      EquipmentStatus.CONFIRMED,
      EquipmentStatus.RETURNED,
    ],
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  useEffect(() => {
    setQuery({
      ...query,
      limit: pagination.pageSize,
      offset: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  const { error, data } = useEquipmentRequests(selectedHub.slug, query);

  useEffect(() => {
    setRiders(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) / (data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  return (
    <div className="flex flex-col flex-1">
      <div className="relative mb-16">
        {error && <div className="text-pink text-xs">{error}</div>}
        {<ArchiveOverviewTable archiveEquipmentRiders={riders} />}
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
    </div>
  );
};

export default ArchiveOverview;
