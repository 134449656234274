import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Th, Thead, Tr } from '@hubportal/components';
import EmptyState from 'components/empty-state';

export const EligibleOverviewTable = ({
  eligibleRiders,
}: {
  eligibleRiders: EligibleRider[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        gridTemplateColumns={`
        40px
        minmax(50px, 1fr)
        minmax(50px, 1fr)
        minmax(200px, 3fr)
    `}
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th>{t('eligible_rider_name')}</Th>
            <Th>{t('eligible_rider_employee_number')}</Th>
            <Th>{t('eligible_rider_available_bundle_keys')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {eligibleRiders?.map((riderEquipment) => {
            return (
              <Tr key={riderEquipment.rider_id}>
                <Td></Td>
                <Td>
                  {`${riderEquipment.first_name || ''} ${
                    riderEquipment.last_name || ''
                  }`}
                </Td>
                <Td>{riderEquipment?.employee_number}</Td>
                <Td>
                  {riderEquipment?.available_bundle_keys
                    .map((bundle) => t(bundle))
                    .join(', ')}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {eligibleRiders?.length === 0 && <EmptyState />}
    </>
  );
};
