import { useTranslation } from 'react-i18next';
import { Table, Tbody, Td, Th, Thead, Tr } from '@hubportal/components';
import { convertDateToShortDate } from 'utils/helpers';
import EmptyState from 'components/empty-state';

export const ArchiveOverviewTable = ({
  archiveEquipmentRiders,
}: {
  archiveEquipmentRiders: RiderEquipment[];
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Table
        gridTemplateColumns={`
        40px
        minmax(100px, 2fr)
        minmax(100px, 1fr)
        minmax(100px, 1fr)
        minmax(100px, 1fr)
        minmax(100px, 1fr)
        minmax(100px, 1fr)
        40px
    `}
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th>{t('claimed_rider_name')}</Th>
            <Th>{t('claimed_status')}</Th>
            <Th>{t('claimed_requested_date')}</Th>
            <Th>{t('claimed_bundle_type')}</Th>
            <Th>{t('claimed_size')}</Th>
            <Th>{t('claimed_style')}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {archiveEquipmentRiders?.map((riderEquipment) => {
            return (
              <Tr key={riderEquipment.id}>
                <Td></Td>
                <Td>
                  {`${riderEquipment.rider?.first_name || ''} ${
                    riderEquipment.rider?.last_name || ''
                  }`}
                </Td>
                <Td>{t(riderEquipment.state)}</Td>
                <Td>{convertDateToShortDate(riderEquipment?.requested_at)}</Td>
                <Td>{t(riderEquipment?.equipment_bundle_key)}</Td>
                <Td>{riderEquipment?.variation?.size}</Td>
                <Td>{riderEquipment?.variation?.fit}</Td>
                <Td></Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {archiveEquipmentRiders?.length === 0 && <EmptyState />}
    </>
  );
};
