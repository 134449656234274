import { Pagination } from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEligibleRiders } from 'utils/network/apis';
import useStore from 'utils/store';
import { EligibleOverviewTable } from './table';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_RIDERS_QUERY = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

export type EligibleRiderResponse = {
  pagination?: {
    total?: number;
    count?: number;
    limit?: number;
    offset?: number;
  };
  results?: EligibleRider[];
};

type EligibleRidersQuery = {
  limit: number;
  offset: number;
};

const EligibleOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedHub } = useStore();

  const [riders, setRiders] = useState<EligibleRider[]>([]);
  const [data, setData] = useState<EligibleRiderResponse>({});
  const [error, setError] = useState<string | null>(null);

  const [query, setQuery] = useState<EligibleRidersQuery>({
    ...DEFAULT_RIDERS_QUERY,
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    pageCount: 0,
  });

  useEffect(() => {
    setQuery({
      ...query,
      limit: pagination.pageSize,
      offset: pagination.pageSize * pagination.pageIndex,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setRiders(data?.results || []);
    setPagination({
      ...pagination,
      pageCount: Math.ceil(
        (data?.pagination?.total || 1) / (data?.pagination?.limit || 1)
      ),
    });
  }, [data]);

  const getHubEligibleRiders = async (): Promise<void> => {
    setError(null);
    try {
      const response = await getEligibleRiders(selectedHub.slug, {
        ...DEFAULT_RIDERS_QUERY,
        limit: pagination.pageSize,
        offset: pagination.pageSize * pagination.pageIndex,
      });

      setData(response);
    } catch (err: any) {
      setError(err?.message);
    }
  };

  useEffect(() => {
    getHubEligibleRiders();
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <div className="flex flex-col flex-1">
      <div className="relative mb-16">
        {error && <div className="text-pink text-xs">{error}</div>}
        <EligibleOverviewTable eligibleRiders={riders} />
      </div>
      <div className="px-10 py-2">
        <Pagination
          goToPage={(pi) => {
            setPagination({ ...pagination, pageIndex: pi });
          }}
          pageCount={pagination.pageCount}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={(ps) => {
            setPagination({ ...pagination, pageSize: ps });
          }}
          totalCount={data?.pagination?.total || 0}
          labels={{ show: t('show') }}
        />
      </div>
    </div>
  );
};

export default EligibleOverview;
