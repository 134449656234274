import { useState } from 'react';
import { getEquipmentRequests } from 'utils/network/apis';
import { useDeepCompareEffect } from './useDeepCompareEffect';

export type EquipmentRequestsStatus = 'idle' | 'loading' | 'success' | 'error';

export type EquipmentStates =
  | 'ordered'
  | 'shipped'
  | 'delivered'
  | 'returned'
  | 'rejected'
  | 'confirmed'
  | 'claimed'
  | 'unclaimed'
  | 'requested';

export type EquipmentRequestsQuery = {
  limit: number;
  offset: number;
  selectedStates?: EquipmentStates[];
};

export type EquipmentRequestsResponse = {
  status: EquipmentRequestsStatus;
  error: string | null;
  data: {
    pagination?: {
      count?: number;
      total?: number;
      limit?: number;
      offset?: number;
    };
    results?: RiderEquipment[];
  };
  refetch: () => Promise<void>;
};

const useEquipmentRequests = (
  hubSlug: string,
  query: EquipmentRequestsQuery
): EquipmentRequestsResponse => {
  const [status, setStatus] = useState<EquipmentRequestsStatus>('idle');
  const [data, setData] = useState<{
    pagination?: { count?: number; total?: number };
    results?: RiderEquipment[];
  }>({});
  const [error, setError] = useState(null);

  const equipmentRequests = async (): Promise<void> => {
    setStatus('loading');
    setError(null);

    try {
      const { limit, offset, selectedStates } = query;

      const response = await getEquipmentRequests(hubSlug, {
        limit,
        offset,
        ...(selectedStates ? { states: selectedStates } : null),
      });

      setData(response);
      setStatus('success');
    } catch (err: any) {
      setStatus('error');
      setError(
        err?.response?.status === 403 ? 'permission_required' : err?.message
      );
    }
  };

  useDeepCompareEffect(() => {
    if (!query) {
      return;
    }

    equipmentRequests();
  }, [query]);

  return { status, error, data, refetch: () => equipmentRequests() };
};

export default useEquipmentRequests;
